<template>
  <div class="home">

    <Header
      :heading="localize('akciya')['banner']"
      :title="localize('akciya')['bannerTitle']"
      :btnTo="'/sign-up'"
      :img="imgSecond"
      :btnText="localize('button')['newmore']"
      class="header-add unique-add"
      padding-bottom="136px"
      padding-top="130px"
      text-color="#fff"
      :has-image-style-addons="true"
    />

    <section class="container pr-0 pl-0">
      <div class="container pt-17">
        <div class="news_title">
          <div v-text="localize('promotion')['title']"></div>
        </div>
      </div>
      <div class="promotion-page">
        <div class="banner">
          <div class="row promotion-photo" >
            <div class="col-md-7 text " v-if="!promotionFinished">
              <h2 class="mb-8 banner-title">
                <a
                  href="https://www.google.com/maps/place/%D0%94%D0%B5%D0%BF%D0%BE+mall/@41.2726678,69.1706902,17.08z/data=!4m5!3m4!1s0x0:0xc4e2d423157e2c9a!8m2!3d41.2730867!4d69.1693131"
                  target="_blank"
                >
                  {{localize('promotion')['place']}}
                </a>
              </h2>
              <p class="mb-16" style="color: #fff; font-size: 20px;">{{promotionDate}}</p>
              <promo-timer
                class="positionTimer"
              />
              <div class="col-md-3 mt-25 banner-link">
                <router-link
                  :to="{name: 'stock' }"
                  style="color: #fff"
                  class="mt-25 d-flex align-items-center"
                >
                  <span>{{localize('button')['more']}}</span>
                  <img
                    alt="arrow"
                    :src="linkArrow"
                  />
                </router-link>
              </div>
            </div>
            <div class="col-md-7 text without-time" v-else>
              <h2 class="mb-8 banner-title">
                {{localize('promotion')['empty_promotion']}}
              </h2>
              <div class="mb-16" style="color: #fff; font-size: 22px; padding: 0;">
                <h2>{{localize('promotion')['empty_soon']}}</h2>
                <p> {{localize('promotion')['empty_notice']}}</p>
              </div>

            </div>
            <div class="col-md-5 img">
              <img
                class=""
                src="../assets/img/promotions/promotion_gift.png"
                alt="akciya"
                :style="{width: '550px'}"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cardNew">

      <!--                  <div class="container">-->
      <!--                    <swiper class="swiper" >-->
      <!--                      <swiper-slide-->
      <!--                        class="swiper_slider"-->

      <!--                        v-for="newsData in news"-->

      <!--                        :key="newsData.id"-->
      <!--                      >-->
      <!--                        <news-card-second-->
      <!--                          :id="newsData.id"-->
      <!--                          v-if="newsData.upcoming"-->
      <!--                          :slug="newsData.locale.slug"-->
      <!--                          :image="newsData.locale.image"-->
      <!--                          :date="newsData.date"-->
      <!--                          :props-timer="true"-->
      <!--                          :styleBack="true"-->
      <!--                          to="stock"-->
      <!--                        />-->
      <!--                      </swiper-slide>-->

      <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
      <!--                    </swiper>-->
      <!--                  </div>-->

      <div class="container">
        <div class="news_title">
          <div v-text="localize('promotion')['secondTitle']"></div>

        </div>
      </div>
      <div class="container second-card">
        <swiper class="swiper" :options="swiperOption">
          <!--            <swiper-slide-->
          <!--                    class="swiper_slider"-->
          <!--                    v-for="newsData in lastNews.filter((item) => item.status === true)"-->
          <!--                    :key="newsData.id"-->
          <!--            >-->
          <!--                <news-card-->
          <!--                        :id="newsData.id"-->
          <!--                        :slug="newsData.locale.slug"-->
          <!--                        :image="newsData.locale.image"-->
          <!--                        :title="newsData.locale.title"-->
          <!--                        :heading="newsData.locale.preview_text"-->
          <!--                        :date="newsData.date"-->
          <!--                        :styleBack="true"-->
          <!--                        to="promotion"-->
          <!--                />-->
          <!--            </swiper-slide>-->
          <!--          <vdd :value="lastNews"></vdd>-->
          <swiper-slide
            class="swiper_slider"
            v-for="newsData in promotionEvents"
            :key="newsData.id"
          >
            <news-card
              :id="newsData.id"
              :slug="newsData.locale.slug"
              :image="newsData.locale.image"
              :title="newsData.locale.title"
              :heading="newsData.locale.preview_text"
              :date="newsData.locale.date"
              :styleBack="true"
              to="promotion"
              class="gradientCard"
              :body-padding="false"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>


        </swiper>
      </div>
      <!-- смещение картинки -->
      <div class="container container-main">
        <div class="news_title" v-text="localize('promotion')['thirdTitle']"></div>
        <div class="row desktop-gallery">
          <div class="col-md-6 col-lg-3 col-xl-3" v-for="(card, index) in newsCardPromotions"
               :key="index">
            <card-promotion
              :id="lastNews[index].id"
              class="father-class"
              :title="card.title"
              :date-current="card.date"
              :image="card.image.preview"
              :span-number="lastNews[index].image.length"
            />
            <router-link :to="{name:'promotion', params:{ slug: lastNews[index].id } }">
              <img
                class="unique-absolute"
                src="../assets/img/gradient.png"
                alt=""
              >
            </router-link>
            <!--                        name: 'profile.contracts', params: { id: $route.params.id }-->
          </div>

        </div>
        <swiper class="swiper-wrapper mobile-gallery" :options="swiperGallery">
          <swiper-slide
            class="swiper-slide "
            v-for="(card, index) in newsCardPromotions"
            :key="index"
          >
            <card-promotion
              class="father-class"
              :title="card.title"
              :date-current="card.data"
              :image="card.image.preview"
              :span-number="lastNews[index].image.length"
              :id="lastNews[index].id"
              to="promotion"
            />
            <router-link :to="{name:'promotion', params:{ slug: lastNews[index].id } }">
              <img
                class="unique-absolute"
                src="../assets/img/gradient.png"
                alt="">
            </router-link>
          </swiper-slide>
        </swiper>

      </div>

    </section>
  </div>
</template>

<script>
// Sections
import Header from '../components/Header.vue';

import ImageLogo from '../assets/img/ellipse.png';

// Swiper
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

// image
import imgSecond from '../assets/img/gifts.png';

// system
import localize from '../filters/localize.filter';
// components
import newsCard from '../components/NewsCard.vue';
import PromoTimer from '../components/timer/PromoTimer';
// import newsCardSecond from '../components/NewsCardSecond.vue';


// images
import headerBg from '../assets/img/home-page-header-bg.png';
import CardPromotion from '../components/CardPromotion';
// icons
import linkArrow from '../assets/icons/promotionPage/link-arrow.svg';

import dataCard from '../data/data-card';
// import NewsCardSecond from "../components/NewsCardSecond";

SwiperCore.use([Pagination]);

export default {
  name: 'home',
  data: () => ({
    promotionDate: '26.05.2022 в 16:00',
    promotionFinished: false,
    time: 'Mar 26, 2022 16:00:00',
    linkArrow,
    headerBg,
    imgSecond,
    ImageLogo,
    newsCardPromotions: dataCard.newsCardPromotions,
    news: dataCard.news,
    lastNews: dataCard.lastNews,
    cardsNew: localize('news'),
    swiperGallery: {
      slidesPerView: 6,
      spaceBetween: 32,
      freemode: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 1.1,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
      },
    },
    language:
      localStorage.getItem('lang') === 'ru-RU' || !localStorage.getItem('lang') ? 'ru' : 'uz',
    swiperOptionProducts: {
      slidesPerView: 4,
      spaceBetween: 32,
      freeMode: true,
      breakpoints: {
        // when window width is >= 320px
        200: { slidesPerView: 1.1, spaceBetween: 20, freeMode: false },
        // when window width is >= 480px
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
          freeMode: false,
        },
        // when window width is >= 640px
        768: { slidesPerView: 2, spaceBetween: 32, freeMode: false },
        992: { slidesPerView: 3, spaceBetween: 32 },
        1200: { slidesPerView: 2, spaceBetween: 25 },
      },
      pagination: { el: '.swiper-pagination', clickable: true },
    },
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 32,
      freeMode: true,
      breakpoints: {
        // when window width is >= 320px
        200: { slidesPerView: 1.1, spaceBetween: 20, freeMode: false },
        // when window width is >= 480px
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
          freeMode: false,
        },
        // when window width is >= 640px
        768: { slidesPerView: 2, spaceBetween: 32, freeMode: false },
        992: { slidesPerView: 3, spaceBetween: 32 },
        1200: { slidesPerView: 3, spaceBetween: 25 },
      },
      pagination: { el: '.swiper-pagination', clickable: true },
    },
  }),
  components: {
    // NewsCardSecond,
    Header,
    newsCard,
    Swiper,
    SwiperSlide,
    CardPromotion,
    PromoTimer,
  },
  computed: {
    promotionEvents() {
      return this.lastNews.filter(promo => !!promo.status);
    },
  },
  created() {
    const endTime = new Date(this.time);
    const currentTime = new Date();
    if((endTime - currentTime) < 0){
      this.promotionFinished = true;
    }
  },
  methods: {
    localize,
  },
};
</script>

<style lang="scss" scoped>


  /*.addons {*/
  /*  padding: 40px 0 0 264px !important;*/
  /*}*/
  .news_title {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 900;
    font-size: 28px;
    font-family: 'TT Travels Trl', sans-serif;
    font-weight: 700;
    line-height: 40px;
    color: $black;
    
    @media (max-width: $mobile) {
      &.product {
        display: block;
        justify-content: normal;
      }
    }

    @media (max-width: $tablet-xl) {
      &.product {
        display: block;
        justify-content: normal;
        font-size: 30px;
      }
    }
    @media (max-width: $notebook) {
      &.product {
        display: block;
        justify-content: normal;
        font-size: 30px;
      }
    }

  }

  .stock {
    margin: 64px 0 0 0;
    background: linear-gradient(281.86deg, #1E1E1E 10.03%, #424242 82.04%);
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    /*padding: 56px;*/
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;


    .banner {
      .row {
        .text {
          padding: 56px 0 56px 56px;

          @media (max-width: $mobile) {
            padding: 0 16px;
          }

          .title {
            font-size: 32px;
            line-height: 40px;

            @media (max-width: $mobile) {
              font-size: 20px;
            }
          }

          .sub-title {
            color: $white;
            line-height: 24px;
            margin: 0 0 24px 0;
          }

          button {
            padding: 14px 24px;
          }
        }

        .img {
          text-align: center;

          img {
            padding-top: 20px;
            margin: 0;

            @media (max-width: $mobile) {
              width: 180px;
            }
          }
        }
      }
    }
  }

  .shop_link {
    font-family: 'TT Travels Trl', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    color: $main;
    text-decoration: none;
  }

  section.cardNew {
    padding-bottom: 150px;
    padding-top: 14px;

    .swiper {
    }
  }

  @media (max-width: 575px) {
    .news_title h2 {
      font-weight: 900;
      line-height: 30px;
    }
    section.cardNew {
      padding-bottom: 50px;
    }
  }


  .header-add {
    // roubackground: url("../assets/img/background-add.png") 100% 100%/contain no-repeat;
    display: block;
    z-index: 1;
    position: relative;


  }

  Header {
    background-color: #1E1E1E !important;
  }

  .unique-add {
    background: url("../assets/img/background-add.png") no-repeat 100% 84%/100rem 30rem;
    color: #fff;

  }

  header .header_title h1 {
    color: #fff !important;
    z-index: 99;
  }


  .desktop-gallery {
    /*position: relative;*/
    /*background: url("../assets/img/black-white.png") no-repeat right 48px black !important;*/
    @media (max-width: $mobile) {
      display: none;
    }
  }

  .mobile-gallery {
    display: none;
    /*position: relative;*/
    @media (max-width: $mobile) {
      display: block;
    }

  }

  .titleHeading {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-top: 72px;
    margin-bottom: 15px;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      transition: 0.4s;
    }

    .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      background: $main;
    }
  }

  /*.gradientCard{*/
  /*    background: url("../assets/img/gradient.png");*/
  /*}*/
  /*.swiper-slide {*/
  /*  height: 560px !important;*/
  /*}*/
  .father-class {
    position: relative;

  }

  .unique-absolute {
    position: absolute;
    right: auto;
    top: 28%;
    border-radius: 6%;
  }

  /*.swiper-slide{*/
  /*    !*position: relative;*!*/
  /*    !*!*display: block;*!*!*/
  /*    !*background: url("../assets/img/black-white.png") no-repeat right 48px black !important;*!*/
  /*}*/
  /*.container-main{*/
  /*    position: relative;*/
  /*}*/
  .promotion-page {
    margin: 14px 15px 0 15px;
    background: linear-gradient(281.86deg, #1E1E1E 10.03%, #424242 82.04%);
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    /*padding: 56px;*/
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

    .banner {

      .row {
        @media (max-width: $mobile) {
          padding: 24px 24px 0 24px;
        }

        .text {
          padding: 40px 0 40px 48px;

          @media (max-width: $mobile) {
            padding: 0 16px;
          }

          .title {
            font-size: 32px;
            line-height: 40px;

            @media (max-width: $mobile) {
              font-size: 20px;
            }
          }

          .sub-title {
            color: $white;
            line-height: 24px;
            margin: 0 0 24px 0;
          }

          button {
            padding: 14px 24px;
          }
        }

        .img {
          text-align: center;
          margin-top: 38px;
          @media (max-width: $mobile) {
            margin-top: 0;
          }

          img {
            width: 284px;

            @media (max-width: $mobile) {
              width: 180px;
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .positionTimer {
    background-color: #ffffff;
    color: #000000;
  }

  .banner {
    &-title {
      color: $main;
      font-weight: 700;
      font-size: 40px;

      &:hover {
        color: #F9A74E;
        opacity: .8;
      }

      a {
        color: $main;
      }
    }

    &-link {
      padding-left: 0;

      a {
        color: #6610F5 !important;
        font-weight: 700;
        font-size: 18px;

        &:hover {
          color: #F9A74E;
          opacity: .8;
        }

        span {
          margin-right: 10px;

        }

      }
    }

    &-img {
      img {
        /*margin-top: 72px;*/
      }
    }
  }
  .without-time{
    .banner-title{
      font-size: 55px;
      &:hover{
        pointer-events: none;
      }
    }
    div{
      h2{
        display: block;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 22px;
        font-size: 36px;
      }
      p{
        line-height: 33px;
      }
    }

  }
</style>
