<template>
  <router-link
    tag="div"
    class="card unique-slide"
    :to="{ name: 'promotion', params: { slug: id } }"


  >
    <img class="unique-slide__image" :src="`${image}`" alt="image">
    <div class="card-heading d-flex">

      <div class="card-items">
        <div class="card-items__title">{{ title }}</div>
        <div class="card-items__date" v-text="dateCurrent" />
      </div>
      <div class="card-logo">
        <img class="card-logo__img" src="../assets/img/ellipse.png" alt="Ellipse">
        <span class="card-logo__span">+{{spanNumber}}</span>
      </div>
    </div>


  </router-link>
</template>

<script>
import VueTypes from 'vue-types';
import paymartLogo from '../assets/Logo.svg';
// import { shuffle } from 'lodash'

export default {
  data() {
    return {
      defaultLogo: paymartLogo,
    };
  },
  props: {
    id: VueTypes.number.def(2),
    logo: VueTypes.string.def,
    title: VueTypes.string.def('Samarqand darvoza'),
    image: VueTypes.string.def('https://picsum.photos/280/220'),
    dateCurrent: VueTypes.string.def('21.01.2021'),
    // partnerCategories: VueTypes.array.isRequired,
    // categories: VueTypes.array.isRequired,
    spanNumber: VueTypes.number.isRequired,
  },
};
</script>
<style lang="scss" scoped>
  .card {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 12px;
    overflow: hidden;
    color: $white;
    cursor: pointer;
    width: 280px;
    height: 171px;
    transition: box-shadow .2s ease-in;
    padding: 5%;
    margin: 32px 0 0 0;

    &-heading {
      &-items {
        h3 {
          font-size: 14px;
          font-weight: 700;
        }

        &__date {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    &-logo {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 131px;

      &__img {

      }

      &__span {
        position: absolute;
        top: 36%;
        right: 24%;
        font-size: 10px;
        font-weight: 700;
      }
    }

    &:hover {
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.35);
    }


  }

  .card-items {
    z-index: 3;

    &__date {

    }

    .__title {
      z-index: 3;
      color: white;
    }
  }

  .unique-slide {
    position: relative;
    background: url("../assets/img/black-white.png") no-repeat right 48px !important;

    &__image {
      position: absolute;
      top: 0%;
      right: 0%;
    }
  }
</style>
